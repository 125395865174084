import React from "react";
import { Link } from "react-router-dom";

function Adoption() {
  return (
    <section className="adoption-area">
      <div className="container">
        <div className="row align-items-center align-items-xl-end justify-content-center">
          <div className="col-xl-7 col-lg-6 col-md-10 order-0 order-lg-2">
            <div className="adoption-img">
              <img
                src="https://res.cloudinary.com/dcz1edokg/image/upload/v1675885808/MOLOS/adoption_mask_rprjmc.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 col-lg-6">
            <div className="adoption-content">
              <h2 className="title">
                30+ Years <span>Proud</span> Journey
              </h2>
              <p>
                Since 1993, we’ve been proudly supporting moloss breeds in
                Zagreb and Croatia. Motivated by a passion for animals and deep
                understanding of quality breeding, we continue to serve a
                growing member base and expand as a Club. When you and your
                furry friend come to our Club, you become part of our family.
              </p>
              <Link to="#" className="btn">
                About Us <img src="img/icon/w_pawprint.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="adoption-shape">
        <img src="img/images/adoption_shape.png" alt="" />
      </div>
    </section>
  );
}

export default Adoption;
