import React from "react";

function Catagories() {
  return (
    <div className="widget">
      <h4 className="sidebar-title">Categories</h4>
      <div className="sidebar-cat-list">
        <ul>
          <li>
            <a href="/#">
              Siberian Husky <i className="fas fa-angle-double-right" />
            </a>
          </li>
          <li>
            <a href="/#">
              German Sherped <i className="fas fa-angle-double-right" />
            </a>
          </li>
          <li>
            <a href="/#">
              French Bulldog <i className="fas fa-angle-double-right" />
            </a>
          </li>
          <li>
            <a href="/#">
              Golden Retriever <i className="fas fa-angle-double-right" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Catagories;
