import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-top-area footer-bg">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-3">
              <div className="footer-widget">
                <div className="f-logo">
                  <Link to="/">
                    <img
                      src="https://res.cloudinary.com/dcz1edokg/image/upload/v1675882635/MOLOS/logo_1_kp69yc.png"
                      alt="Logo"
                    />
                  </Link>
                </div>
                <div className="footer-text">
                  <p>
                    Since 1993, we’ve been proudly supporting moloss breeds in
                    Zagreb and Croatia. Motivated by a passion for animals and
                    deep understanding of quality breeding,
                  </p>
                </div>
                <div className="footer-contact">
                  <div className="icon">
                    <i className="fas fa-headphones" />
                  </div>
                  <div className="content">
                    <h4 className="title">
                      <a href="tel:+385915312298">+385 (0)91 531 2298</a>
                    </h4>
                    <span>Call Now</span>
                  </div>
                </div>
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href="/#">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <i className="fab fa-linkedin-in" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Our Policies</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Our Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="footer-widget">
                <h4 className="fw-title">Site Navigation</h4>
                <div className="fw-link">
                  <ul>
                    <li>
                      <Link to="#">Home</Link>
                    </li>
                    <li>
                      <Link to="#">Breeders</Link>
                    </li>
                    <li>
                      <Link to="#">Studs</Link>
                    </li>
                    <li>
                      <Link to="#">Litters</Link>
                    </li>
                    <li>
                      <Link to="#">Events</Link>
                    </li>
                    <li>
                      <Link to="#">Blog</Link>
                    </li>
                    <li>
                      <Link to="#">Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="footer-widget">
                <h4 className="fw-title">Instagram</h4>
                <div className="fw-insta">
                  <ul>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img01.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img02.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img03.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img04.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img05.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="/#">
                        <img src="img/images/insta_img06.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img src="img/images/footer_shape01.png" alt="" />
        </div>
        <div className="footer-shape shape-two">
          <img src="img/images/footer_shape02.png" alt="" />
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="copyright-text">
                <p>
                  Copyright © 2023 Kinoloski Klub Moloških . All Rights
                  Reserved.
                </p>
              </div>
            </div>
            <div className="col-md-4 d-none d-md-block"></div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
