import React from "react";
import { Link } from "react-router-dom";

function Find() {
  return (
    <div className="find-area">
      <div className="container custom-container">
        <div className="row">
          <div className="col-12">
            <form>
              <div className="find-wrap">
                <div className="location">
                  <i className="flaticon-location" />
                  {/* <input type="text" defaultValue="Enter City, State. or Zip" /> */}
                  <span>Moloss Club,Zagreb,Croatia</span>
                </div>
                <div className="find-category">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="flaticon-husky" />
                        Breeders
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="flaticon-husky" /> Studs
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="flaticon-husky" />
                        Litters
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="other-find">
                  <div className="dropdown">
                    <button
                      className="btn "
                      type="button"
                      id="dropdownMenuButton"
                    >
                      Get in Touch!
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Find;
