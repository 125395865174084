import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import Home from "./pages/Home";
import DogList from "./pages/DogList";
import AdoptionsPages from "./pages/AdoptionsPages";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import BlogDetailsPage from "./pages/BlogDetailsPage";
import ShopPages from "./pages/ShopPages";
import ShopDetailsPage from "./pages/ShopDetailsPage";
import BreederPage from "./pages/BreederPage";
import BreederDetailsPage from "./pages/BreederDetailsPage";
import HometwoPage from "./pages/HometwoPage";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import $ from "jquery";
import ScrollToTop from "./scrollToTop";
import React from "react";

export const App = () => {
  useEffect(() => {
    $("#preloader").delay(0).fadeOut();
  }, []);
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/doglist" element={<DogList />} />
          <Route path="/adoption" element={<AdoptionsPages />} />
          <Route path="/contacts" element={<ContactPage />} />
          <Route path="/blogs" element={<BlogPage />} />
          <Route path="/blog-details" element={<BlogDetailsPage />} />
          <Route path="/shop" element={<ShopPages />} />
          <Route path="/shop-details" element={<ShopDetailsPage />} />
          <Route path="/breeder" element={<BreederPage />} />
          <Route path="/breeder-details" element={<BreederDetailsPage />} />
          <Route path="/home-two" element={<HometwoPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};
